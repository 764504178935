import { isProd, isTest } from 'utils'
import { TUser } from 'types'

export async function fetchUsers(usersToFetchCount = 1): Promise<TUser[]> {
    !isProd && !isTest && console.log(`Fetching ${usersToFetchCount} users`)

    try {
        const result = await fetch(`https://randomuser.me/api/?results=${usersToFetchCount}`)
        const { results, error } = await result.json()

        if (error) {
            throw new Error(error)
        }

        return results
    } catch (error) {
        !isProd && !isTest && console.log('Error fetching users:', error)

        throw new Error()
    }
}
