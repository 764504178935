import React from 'react'
import { useState, useRef } from 'react'
import Slider from 'react-slick'
import { ErrorBoundary } from 'react-error-boundary'
import { StatusCard, UserCard } from 'components'
import { BREAKPOINTS, INITIAL_SLIDE, VISIBLE_CARDS_COUNT } from 'const'
import { TUser } from 'types/user'
import { Container, RestartButton, SlideButton } from './carousel.styles'

type TCarouselProps = {
    handleLoadMore: (currentIndex: number) => void
    hasErrorFetchingMore: boolean
    isLoadingMore: boolean
    users: TUser[]
}

export const Carousel: React.FC<TCarouselProps> = ({ handleLoadMore, hasErrorFetchingMore, isLoadingMore, users }) => {
    const sliderRef = useRef<Slider>(null)
    const [currentSlide, setCurrentSlide] = useState(INITIAL_SLIDE)

    const handleBeforeChange = (_: number, newIndex: number): void => {
        setCurrentSlide(newIndex)
    }

    const handleRestart = (): void => {
        sliderRef.current?.slickGoTo(0)
    }

    const settings = {
        afterChange: handleLoadMore,
        beforeChange: handleBeforeChange,
        infinite: false,
        initialSlide: INITIAL_SLIDE,
        nextArrow: <SlideButton />,
        prevArrow: <SlideButton isPrev />,
        slidesToScroll: VISIBLE_CARDS_COUNT.XL,
        slidesToShow: VISIBLE_CARDS_COUNT.XL,
        speed: 700,
        swipeToSlide: true,
        responsive: [
            // This uses max-width
            {
                breakpoint: BREAKPOINTS.SM,
                settings: {
                    slidesToScroll: VISIBLE_CARDS_COUNT.BASE,
                    slidesToShow: VISIBLE_CARDS_COUNT.BASE,
                    speed: 300,
                },
            },
            {
                breakpoint: BREAKPOINTS.MD,
                settings: {
                    slidesToScroll: VISIBLE_CARDS_COUNT.SM,
                    slidesToShow: VISIBLE_CARDS_COUNT.SM,
                    speed: 400,
                },
            },
            {
                breakpoint: BREAKPOINTS.LG,
                settings: {
                    slidesToScroll: VISIBLE_CARDS_COUNT.MD,
                    slidesToShow: VISIBLE_CARDS_COUNT.MD,
                    speed: 500,
                },
            },
            {
                breakpoint: BREAKPOINTS.XL,
                settings: {
                    slidesToScroll: VISIBLE_CARDS_COUNT.LG,
                    slidesToShow: VISIBLE_CARDS_COUNT.LG,
                    speed: 600,
                },
            },
        ],
    }

    return (
        <Container>
            <Slider ref={sliderRef} {...settings}>
                {users.map((user) => (
                    <ErrorBoundary fallbackRender={() => <StatusCard status="error" />} key={user.login.uuid}>
                        <UserCard user={user} />
                    </ErrorBoundary>
                ))}

                {isLoadingMore && <StatusCard status="loading" />}

                {hasErrorFetchingMore && <StatusCard status="error" />}
            </Slider>

            <RestartButton handleRestart={handleRestart} isDisabled={currentSlide === INITIAL_SLIDE}>
                Back To Beginning
            </RestartButton>
        </Container>
    )
}
