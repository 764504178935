export const isDev = process.env.NODE_ENV === 'development'
export const isTest = process.env.NODE_ENV === 'test'
export const isStaging = process.env.REACT_APP_VERCEL_ENV === 'preview'
export const isProd = process.env.REACT_APP_VERCEL_ENV === 'production'

export function getRuntimeEnv():string {
    if (isDev) return 'Dev'
    if (isStaging) return 'Staging'
    return 'Production'
}
