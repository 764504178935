import React from 'react'
import { Card, Spinner, Text } from 'components'
import {} from 'const'

type TStatusCardProps = {
    status: 'loading' | 'error'
}

export const StatusCard: React.FC<TStatusCardProps> = ({ status }) => {
    if (status === 'loading') {
        return (
            <Card>
                <Spinner data-testid="loading-more-spinner" size="xl" />
            </Card>
        )
    }

    if (status === 'error') {
        return (
            <Card>
                <Text data-testid="fetching-more-error" textStyle="fetch-more-error">
                    This user could <br />
                    not be loaded
                </Text>
            </Card>
        )
    }

    return null
}
