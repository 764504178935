import React from 'react'
import ReactDOM from 'react-dom'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { theme } from 'styles'
import App from './app'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const customTheme = extendTheme(theme)

ReactDOM.render(
    <React.StrictMode>
        <ChakraProvider theme={customTheme}>
            <App />
        </ChakraProvider>
    </React.StrictMode>,
    document.getElementById('root'),
)
