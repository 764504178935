import React from 'react'
import { Flex } from 'components'
import { Footer, Header, Main, RuntimeBadge } from 'components'
import { getRuntimeEnv, isProd } from 'utils'

const App: React.FC = () => (
    <Flex
        direction="column"
        marginX="auto"
        maxWidth="1800px"
        minHeight="100vh"
        overflowX="hidden"
        paddingX={{ base: '40px', sm: '50px', md: '60px' }}
    >
        <Header />

        <Main />

        <Footer />

        {!isProd && <RuntimeBadge currentEnv={getRuntimeEnv()} />}
    </Flex>
)

export default App
