import React from 'react'
import { Box, Text } from 'components'

type TRuntimeBadgeProps = {
    currentEnv: string
}

export const RuntimeBadge: React.FC<TRuntimeBadgeProps> = ({ currentEnv }) => (
    <Box background="red.800" bottom="0" left="0" padding="5px 10px" position="fixed">
        <Text fontSize="13px" fontWeight="700" textTransform="uppercase">
            {currentEnv}
        </Text>
    </Box>
)
