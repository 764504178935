import React from 'react'
import { Box, Card } from 'components'
import { TUser } from 'types'
import { Container, UserInformation, UserName, UserPicture } from './user-card.styles'

type TUserCardProps = {
    user: TUser
}

export const UserCard: React.FC<TUserCardProps> = ({ user }) => {
    const { email, location, name, picture, phone } = user
    const { city, country, state, street } = location

    return (
        <Card>
            <Container>
                <UserPicture name={name.first} picture={picture.large} />

                <Box>
                    <UserName text={`${name.first} ${name.last}`} />
                    <UserInformation text={phone} />
                    <UserInformation text={email} />
                    <UserInformation text={`${street.name} ${street.number}`} />
                    <UserInformation text={`${city}, ${state}`} />
                    <UserInformation text={country} />
                </Box>
            </Container>
        </Card>
    )
}
