import { CUSTOM_COLOR } from 'const'

export const theme = {
    styles: {
        global: {
            body: {
                color: 'gray.50',
                background: 'gray.900',
                fontFamily: 'Inter, system-ui, sans-serif',
            },
        },
    },
    textStyles: {
        h1: {
            fontFamily: 'Pattaya, sans-serif',
            fontSize: ['38px', '50px', '60px', '72px'],
            fontWeight: '900',
            letterSpacing: '2px',
            lineHeight: '100%',
        },
        'user-name': {
            background: `var(--${CUSTOM_COLOR})`,
            borderRadius: '3px',
            fontSize: '16px',
            fontWeight: '600',
            left: '-3px',
            marginBottom: '5px',
            paddingX: '3px',
            position: 'relative',
            width: 'fit-content',
        },
        'user-information': {
            color: 'gray.400',
            fontSize: '11px',
            fontWeight: '600',
            marginY: '1px',
        },
        'created-by': {
            color: 'gray.400',
            fontSize: '12px',
        },
        'fetch-initial-error': {
            fontSize: ['16px', '22px'],
            fontWeight: 'bold',
            textAlign: 'center',
        },
        'fetch-more-error': {
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
        },
    },
}
