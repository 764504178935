import React from 'react'
import { Box, Button, Popover, PopoverContent, PopoverTrigger } from 'components'
import { COLORS } from 'const'

const { DARK_GRAY } = COLORS

export const Container: React.FC = ({ children }) => (
    <Box position="absolute" top="0" width="100%">
        <Popover placement="bottom-start">{children}</Popover>
    </Box>
)

export const SelectColorButton: React.FC = ({ children }) => (
    <PopoverTrigger>
        <Button fontSize="15px" variant="outline" width={{ base: '100%', sm: 'fit-content' }} _active={{}} _hover={{}}>
            {children}
        </Button>
    </PopoverTrigger>
)

type TColorPreviewProps = {
    color: string
}

export const ColorPreview: React.FC<TColorPreviewProps> = ({ color }) => (
    <Box
        as="span"
        data-testid="color-preview"
        height="20px"
        width="40px"
        marginLeft="10px"
        borderRadius="3px"
        background={color}
    />
)

export const ContentContainer: React.FC = ({ children }) => (
    <PopoverContent
        color="black"
        sx={{
            '.sketch-picker': {
                borderBottomLeftRadius: '0 !important',
                borderBottomRightRadius: '0 !important',
                borderBottom: '1px solid rgb(238, 238, 238)',
            },
        }}
        width="auto"
    >
        {children}
    </PopoverContent>
)

type TResetButtonProps = {
    isDisabled: boolean
    handleResetColor: () => void
}

export const ResetButton: React.FC<TResetButtonProps> = ({ children, isDisabled, handleResetColor }) => (
    <Button
        background="white"
        borderTopLeftRadius="0"
        borderTopRightRadius="0"
        color={DARK_GRAY}
        data-testid="reset-color"
        fontSize="15px"
        isDisabled={isDisabled}
        onClick={handleResetColor}
        _hover={{}}
        _active={{}}
    >
        {children}
    </Button>
)
