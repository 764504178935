import React, { useEffect, useState } from 'react'
import { fetchUsers } from 'api'
import { Box, Carousel, ColorPicker, Flex, Spinner, Text } from 'components'
import { BREAKPOINTS, VISIBLE_CARDS_COUNT } from 'const'
import { isProd } from 'utils'
import { TUser } from 'types'

const LOADING = {
    INITIAL: 'loading-initial',
    MORE: 'loading-more',
}

const ERROR = {
    INITIAL: 'error-initial',
    MORE: 'error-more',
}

const getVisibleCardsCount = (): number => {
    if (window.innerWidth <= BREAKPOINTS.SM) return VISIBLE_CARDS_COUNT.BASE
    if (window.innerWidth <= BREAKPOINTS.MD) return VISIBLE_CARDS_COUNT.SM
    if (window.innerWidth <= BREAKPOINTS.LG) return VISIBLE_CARDS_COUNT.MD
    if (window.innerWidth <= BREAKPOINTS.XL) return VISIBLE_CARDS_COUNT.LG
    return VISIBLE_CARDS_COUNT.XL
}

export const Main: React.FC = () => {
    const [users, setUsers] = useState<[] | TUser[]>([])
    const [status, setStatus] = useState<string | null>(LOADING.INITIAL)

    useEffect(() => {
        const initialFetch = async () => {
            try {
                const initialFetchCount = window.innerWidth >= BREAKPOINTS.LG ? 20 : 10
                const result = await fetchUsers(initialFetchCount)

                setUsers(result)
                setStatus(null)
            } catch (error) {
                setStatus(ERROR.INITIAL)
            }
        }

        initialFetch()
    }, [])

    const handleLoadMore = async (currentIndex: number): Promise<void> => {
        const cardsAlreadySeenCount = currentIndex + getVisibleCardsCount()
        const cardsNotSeenCount = users.length - cardsAlreadySeenCount
        const offsetCount = window.innerWidth >= BREAKPOINTS.LG ? 10 : 5

        const shouldFetchMore = cardsNotSeenCount <= offsetCount
        const hasReachedLimit = users.length >= 100
        const isLoading = status === LOADING.INITIAL || status === LOADING.MORE

        !isProd && console.log('Cards to the right:', cardsNotSeenCount)

        if (shouldFetchMore && !hasReachedLimit && !isLoading) {
            setStatus(LOADING.MORE)

            try {
                const result = await fetchUsers(offsetCount)

                setStatus(null)
                setUsers([...users, ...result])
            } catch (error) {
                setStatus(ERROR.MORE)
            }
        }
    }

    const renderResult = (): React.ReactNode => {
        if (status === LOADING.INITIAL) {
            return <Spinner data-testid="loading-initial-spinner" display="block" marginX="auto" size="xl" />
        }

        if (users?.length > 0) {
            return (
                <>
                    <ColorPicker />

                    <Carousel
                        handleLoadMore={handleLoadMore}
                        hasErrorFetchingMore={status === ERROR.MORE}
                        isLoadingMore={status === LOADING.MORE}
                        users={users}
                    />
                </>
            )
        }

        return (
            <Text data-testid="fetching-initial-error" textStyle="fetch-initial-error">
                Something went wrong.
                <br />
                Please refresh the page.
            </Text>
        )
    }

    return (
        <Flex as="main" direction="column" flex="1" justifyContent="center">
            <Box height="460px" paddingY="70px" position="relative">
                {renderResult()}
            </Box>
        </Flex>
    )
}
