import React from 'react'
import { Flex, Text } from 'components'
import { SIZES } from 'const'

const { HEADER_AND_FOOTER_HEIGHT, HEADER_AND_FOOTER_Y_PADDING } = SIZES

export const Header: React.FC = () => (
    <Flex
        alignItems="flex-start"
        as="header"
        height={HEADER_AND_FOOTER_HEIGHT}
        paddingTop={HEADER_AND_FOOTER_Y_PADDING}
    >
        <Text as="h1" textStyle="h1">
            My Clerks
        </Text>
    </Flex>
)
