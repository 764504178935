import React from 'react'
import { Flex, Text } from 'components'
import { SIZES } from 'const'

const { HEADER_AND_FOOTER_HEIGHT, HEADER_AND_FOOTER_Y_PADDING } = SIZES

export const Footer: React.FC = () => (
    <Flex
        alignItems="flex-end"
        as="footer"
        height={HEADER_AND_FOOTER_HEIGHT}
        paddingBottom={HEADER_AND_FOOTER_Y_PADDING}
    >
        <Text textStyle="created-by">
            Created by{' '}
            <Text
                as="a"
                textDecoration="underline"
                fontWeight="bold"
                href="https://twitter.com/marcelcruz"
                rel="noopener"
                target="_blank"
                transition="color 0.25s"
                _hover={{
                    color: 'gray.50',
                }}
            >
                @marcelcruz
            </Text>
        </Text>
    </Flex>
)
