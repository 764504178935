import React from 'react'
import { Avatar, Flex, Text } from 'components'
import { CUSTOM_COLOR, COLORS } from 'const'

const { DARK_GRAY } = COLORS

export const Container: React.FC = ({ children }) => (
    <Flex direction="column" height="100%" justifyContent="space-between" width="100%">
        {children}
    </Flex>
)

type TUserPictureProps = {
    name: string
    picture: string
}

export const UserPicture: React.FC<TUserPictureProps> = ({ name, picture }) => (
    <Avatar
        alt={`${name}'s picture`}
        background={`var(--${CUSTOM_COLOR})`}
        border={`3px solid ${DARK_GRAY}`}
        display="block"
        marginX="auto"
        size="xl"
        src={picture}
    />
)

type TUserData = {
    text: string
}

export const UserName: React.FC<TUserData> = ({ text }) => <Text textStyle="user-name">{text}</Text>

export const UserInformation: React.FC<TUserData> = ({ text }) => <Text textStyle="user-information">{text}</Text>
