import React from 'react'
import { Flex } from 'components'
import { SIZES } from 'const'

const { CARD_HEIGHT } = SIZES

export const Card: React.FC = ({ children }) => (
    <Flex
        align="center"
        as="article"
        background="linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 71.18%, rgba(0, 0, 0, 0.8) 100%)"
        borderRadius="8px"
        height={CARD_HEIGHT}
        justifyContent="center"
        padding="25px"
    >
        {children}
    </Flex>
)
