import React from 'react'
import { ArrowBackIcon, ArrowForwardIcon, Box, Button, Flex } from 'components'
import { CUSTOM_COLOR, SIZES } from 'const'

const { CARD_HEIGHT } = SIZES

export const Container: React.FC = ({ children }) => (
    <Box
        sx={{
            '.slick-slide > div': {
                margin: '0 5px',
            },
            '.slick-list': {
                height: CARD_HEIGHT,
                margin: '0 -5px',
                overflow: 'visible',
            },
        }}
    >
        {children}
    </Box>
)

type TRestartButtonProps = {
    handleRestart: () => void
    isDisabled: boolean
}

export const RestartButton: React.FC<TRestartButtonProps> = ({ children, handleRestart, isDisabled }) => (
    <Button
        background="gray.600"
        bottom={{ base: '0', sm: 'unset' }}
        data-testid="slick-reset"
        fontSize="15px"
        isDisabled={isDisabled}
        onClick={handleRestart}
        position="absolute"
        right={{ base: 'unset', sm: '0' }}
        top={{ base: 'unset', sm: '0' }}
        variant="solid"
        width={{ base: '100%', sm: 'fit-content' }}
        _active={{}}
        _hover={{}}
    >
        {children}
    </Button>
)

type TSlideButtonProps = {
    className?: string
    isPrev?: boolean
    onClick?: () => void
}

export const SlideButton: React.FC<TSlideButtonProps> = ({ className, isPrev, onClick }) => {
    const arrowSize = { base: '24px', md: '30px' }
    const containerSize = { base: '50px', md: '56px' }
    const isDisabled = className?.includes('disabled')

    return (
        <Flex
            align="center"
            as="button"
            background={`var(--${CUSTOM_COLOR})`}
            borderRadius="50%"
            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            cursor={isDisabled ? 'not-allowed' : 'pointer'}
            data-testid={isPrev ? 'slick-prev' : 'slick-next'}
            disabled={isDisabled}
            display={{ base: 'none', sm: 'flex' }}
            height={containerSize}
            justify="center"
            left={isPrev ? '0' : 'unset'}
            onClick={onClick}
            overflow="hidden"
            position="absolute"
            right={!isPrev ? '0px' : 'unset'}
            top="50%"
            transform={`translateY(-50%) translateX(${isPrev ? '-70%' : '70%'})`}
            transition="box-shadow 0.25s"
            width={containerSize}
            zIndex="9"
            _focus={{ boxShadow: 'var(--chakra-shadows-outline)', outline: 'none' }}
        >
            <Box
                background="black"
                height="100%"
                left="0"
                opacity={isDisabled ? '0.6' : '0'}
                position="absolute"
                top="0"
                transition="opacity 0.25s"
                width="100%"
            />
            {isPrev ? (
                <ArrowBackIcon height={arrowSize} width={arrowSize} />
            ) : (
                <ArrowForwardIcon height={arrowSize} width={arrowSize} />
            )}
        </Flex>
    )
}
