import { useState } from 'react'
import { isProd } from 'utils'

export function useLocalStorage<T>(key: string, initialValue: T) {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key)

            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            !isProd && console.log('Error reading from local storage:', error)

            return initialValue
        }
    })

    const setValue = (value: T | ((val: T) => T)): void => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value

            setStoredValue(valueToStore)
            window.localStorage.setItem(key, JSON.stringify(valueToStore))
        } catch (error) {
            !isProd && console.log('Error writing to local storage:', error)
        }
    }

    return [storedValue, setValue]
}
