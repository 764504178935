import React from 'react'
import { useState, useEffect } from 'react'
import { SketchPicker } from 'react-color'
import { CUSTOM_COLOR, COLORS } from 'const'
import { useLocalStorage } from 'hooks'
import { ColorPreview, Container, ContentContainer, ResetButton, SelectColorButton } from './color-picker.styles'

const { BLUE } = COLORS

type TColorPickerProps = {
    initialColor?: string
}

export const ColorPicker: React.FC<TColorPickerProps> = ({ initialColor = BLUE }) => {
    const [{ hex: persistentColor }, setPersistentColor] = useLocalStorage(CUSTOM_COLOR, { hex: initialColor })
    const [{ hex: tempColor }, setTempColor] = useState({ hex: persistentColor })

    useEffect(() => {
        const root = document.querySelector(':root') as HTMLElement
        root.style.setProperty(`--${CUSTOM_COLOR}`, tempColor)
    }, [tempColor])

    const handleResetColor = (): void => {
        setPersistentColor({ hex: BLUE })
        setTempColor({ hex: BLUE })
    }

    return (
        <Container>
            <SelectColorButton>
                Select Color
                <ColorPreview color={tempColor} />
            </SelectColorButton>

            <ContentContainer>
                <SketchPicker color={tempColor} onChange={setTempColor} onChangeComplete={setPersistentColor} />

                <ResetButton isDisabled={persistentColor === initialColor} handleResetColor={handleResetColor}>
                    Reset Color
                </ResetButton>
            </ContentContainer>
        </Container>
    )
}
